<template>
  <div>
    <input ref="input" type="file" :name="name" :id="name" class="hidden" :class="{ 'cursor-not-allowed': pending }" @change="updateValue" />
    <div
      class="flex rounded-md text-sm ring-1 ring-inset ring-input focus-within:ring-primary focus-within:hover:ring-primary"
      :class="{
        'cursor-not-allowed bg-gray-50 text-paragraph/50': pending || disabled,
        'ring-red-500': errors,
        'hover:ring-input-hover': !errors,
      }"
      @click.prevent="considerAddingFile"
    >
      <div class="grow px-4 py-3">
        <span v-if="file?.mime_type" class="grid grid-cols-[1fr_max-content_max-content] gap-x-3">
          <span class="truncate">
            {{ file.name }}
          </span>
          <span class="flex items-center text-xs text-supplement">
            {{ file.filesize }}
          </span>
          <span class="flex items-center text-xs text-gray-400 hover:cursor-pointer hover:text-red-500">
            <Icon icon="times" @click="removeFile" />
          </span>
        </span>
        <span v-else class="text-supplement">{{ $t("select") }}...</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    modelValue: object | null;
    name: string;
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emit = defineEmits(["update:modelValue"]);

const { parseFile, UploadedFile } = useFileInput();

const input = ref();
const { autosave, pending, getFieldErrors, submit } = injectStrict<ProvideForm>("form");
const errors = getFieldErrors(props.name);

const file = computed({
  get: () => (props.modelValue ? new UploadedFile(props.modelValue) : props.modelValue),
  set: async (value) => emit("update:modelValue", value),
});

const disableAdd = ref(false);

const considerAddingFile = () => {
  if (!file.value && !disableAdd.value && !pending.value && !props.disabled) {
    input.value.click();
  }
};

const updateValue = async (event) => {
  const parsedFile = await parseFile(event.target.files[0]);
  file.value = new UploadedFile(parsedFile);
  emit("update:modelValue", parsedFile);

  if (autosave) {
    await submit();
  }
};

const removeFile = async () => {
  disableAdd.value = true;
  file.value = null;
  setTimeout(() => (disableAdd.value = false), 250);

  if (autosave) {
    await submit();
  }
};
</script>
